module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#dd1414',
    secondaryColor: '#d42e12',
    backgroundColor: 'whitesmoke',
  },
  apiKey: 'SRDsCtLQU7LoZcrVEE6x9ajyl4V623tJb9htfM5UQh0Jwws6',
};
